import React, { createContext } from 'react';
import { I18nextProvider } from 'react-i18next';
import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import Header from './components/Header';
import Footer from './components/Footer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

export const GlobalContext = createContext();

i18next
    .use(initReactI18next)
    .init({
        interpolation: { escapeValue: false },
        lng: 'pl',
        resources: {
            en: {
                translation: require('./components/Language/en.json'),
            },
            pl: {
                translation: require('./components/Language/pl.json'),
            },
        }
    });

export const GlobalState = ({ children }) => {

    return (
        <GlobalContext.Provider>
            <I18nextProvider i18n={i18next}>
                <BrowserRouter >
                    <div className="app-container">
                        <Header />
                        <div className="box">
                            {children}
                        </div>
                        <Footer />
                    </div>
                </BrowserRouter>
            </I18nextProvider>
        </GlobalContext.Provider>
    );
};