import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LabToRgb from "./components/converterScreens/LabToRgb";
import RgbToLab from "./components/converterScreens/RgbToLab";
import LuvToRgb from "./components/converterScreens/LuvtoRGB";
import RgbtoRgb from "./components/converterScreens/RgbToRgb";
import RgbToLuv from "./components/converterScreens/RgbToLuv";
import XYYtoRgb from "./components/converterScreens/XYYtoRgb";
import { GlobalState } from "./GlobalContext";
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import multipleIcon from "./assets/multiple-icon.svg"
import MultipleMenu from "./components/multipleInput/MultipleMenu";
import EOTFandOETFTest from "./components/math/EOTFandOETFTest";
import Autors from "./components/Autors";

function getLanguage() {
  const path = window.location.pathname;
  return path.includes('converter') ? 'en' : 'pl';
}
const KonwerterMenu = () => {
  const { t, i18n } = useTranslation();
  const [currentConversion, setCurrentConversion] = useState('labToRgb');
  const navigate = useNavigate();
  const currentLanguage = i18n.language;

  const handleConversionChange = (conversionType) => {
    setCurrentConversion(conversionType);
  };

  const handleRedirectToMultipleMenu = () => {
    const urlPath = currentLanguage === 'pl' ? '/konwerter/multiple' : '/converter/multiple';
    navigate(urlPath);
  };

  return (
    <>
      <div className="norm">{t('title')}<br />{t('title2')}<br />{t('norm')}</div>

      <div className="konwerter-menu">
        <button className={`konwerter-menu-button ${currentConversion === 'labToRgb' ? 'konwerter-menu-button-selected' : ''}`} onClick={() => handleConversionChange('labToRgb')}> L*a*b* {t('to')} RGB </button>
        <button className={`konwerter-menu-button ${currentConversion === 'rgbToLab' ? 'konwerter-menu-button-selected' : ''}`} onClick={() => handleConversionChange('rgbToLab')}> RGB {t('to')} L*a*b* </button>
        <button className={`konwerter-menu-button ${currentConversion === 'luvToRgb' ? 'konwerter-menu-button-selected' : ''}`} onClick={() => handleConversionChange('luvToRgb')}> L*u*v* {t('to')} RGB  </button>
        <button className={`konwerter-menu-button ${currentConversion === 'rgbToLuv' ? 'konwerter-menu-button-selected' : ''}`} onClick={() => handleConversionChange('rgbToLuv')}> RGB {t('to')} L*u*v* </button>
        <button className={`konwerter-menu-button ${currentConversion === 'rgbToRgb' ? 'konwerter-menu-button-selected' : ''}`} onClick={() => handleConversionChange('rgbToRgb')}> RGB {t('to')} RGB </button>
        <button className={`konwerter-menu-button ${currentConversion === 'XYYtoRgb' ? 'konwerter-menu-button-selected' : ''}`} onClick={() => handleConversionChange('XYYtoRgb')}> xyY {t('to')} RGB </button>
        <button className="switch-button" onClick={handleRedirectToMultipleMenu} >
          <img src={multipleIcon} className="button-icon" alt="button" />
          {t("addMore")}
        </button>
      </div>

      {currentConversion === 'labToRgb' ? <LabToRgb /> :
        currentConversion === 'rgbToLab' ? <RgbToLab /> :
          currentConversion === 'luvToRgb' ? <LuvToRgb /> :
            currentConversion === 'rgbToLuv' ? <RgbToLuv /> :
              currentConversion === 'rgbToRgb' ? <RgbtoRgb /> :
                currentConversion === 'XYYtoRgb' ? <XYYtoRgb /> : null}
    </>

  );
};

const App = ({ language }) => {

  useEffect(() => {
    document.title = language === 'pl' ? "Konwerter kolorów" : "Color Converter";
    i18n.changeLanguage(language);
  }, [language]);

  // const test = EOTFandOETFTest()

  return (

    <GlobalState>
      <Routes>
        <Route path="/" element={<KonwerterMenu />} />
        <Route path="/konwerter" element={<KonwerterMenu />} />
        <Route path="/converter" element={<KonwerterMenu />} />
        <Route path="/konwerter/multiple" element={<MultipleMenu />} />
        <Route path="/converter/multiple" element={<MultipleMenu />} />
        <Route path="/konwerter/autorzy" element={<Autors />} />
        <Route path="/converter/authors" element={<Autors />} />
      </Routes>
    </GlobalState>
  );
};

export default function AppWrapper() {
  const language = getLanguage();
  return (
    <App language={language} />
  );
}
